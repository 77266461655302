import React, {FC, useEffect, useState, useContext, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import isValidDate from 'date-fns/isValid';
import {AxiosResponse} from 'axios';
import * as Yup from 'yup';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {Config, MixAndMatchFinanceDetailsSummary} from '../../../types';
import {api, get, put} from '../../../utils/Request';
import {MoneyGraphIcon} from '../../../components/Icons';
import ButtonsContainer from '../../../components/Container/ButtonsContainer';
import Button from '../../../components/Button/Button';
import {OutlinedButton} from '../../../components/Button';
import {TableTabPanel} from '../../../components/TableTabPanel';
import {SplitTextComponent} from '../../../components/SplitTextComponent';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import TabsComponent from '../../../components/TabsComponent';
import EditableField from '../../../components/EditableField';
import MixAndMatchSummaryValuesTable from './MixAndMatchSummaryValuesTable';
import Attachments from '../../../components/MixAndMatchForm/Attachments';

const DownloadButton = styled(Button)`
  width: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 3.625rem;
  background-color: #000000;
  color: #ffffff;
`;

const SummaryReportDownloadButton = styled(OutlinedButton)`
  border-color: black;
  font-size: 1rem;
  width: auto;
  height: 3.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

const TabsIndex = {
  MixAndMatchInformation: 'MixAndMatchInformation',
  Attachments: 'Attachments',
};
const Tabs = [
  {
    value: TabsIndex.MixAndMatchInformation,
    label: 'Mix & Match Information',
  },
  {
    value: TabsIndex.Attachments,
    label: 'Attachments',
  },
];

export const supplierApprovalNumberValidationSchema = Yup.object().shape({
  supplierApprovalNumber: Yup.string().test(
    'max length check',
    'Supplier approval number cannot exceed 100 characters',
    function (value?: string) {
      return !value || value.length <= 100;
    }
  ),
});

export const isMixAndMatchLocked = (configs: Config | undefined, endAt: string): boolean => {
  if (!configs) {
    return false;
  }
  const lockDate = new Date(configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs]);
  const cutoffDate = new Date(
    configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
  );
  if (isValidDate(lockDate) && isValidDate(cutoffDate)) {
    return new Date() >= lockDate && new Date(endAt) < cutoffDate;
  }
  return false;
};

type Props = {
  financeDetailsId: string | number | undefined;
  summaryGstType: string | null;
};

const MixAndMatchFinanceDetails: FC<Props> = ({financeDetailsId, summaryGstType}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [selectedTab, setSelectedTab] = useState(TabsIndex.MixAndMatchInformation);
  const [data, setData] = useState<MixAndMatchFinanceDetailsSummary | undefined>();

  useEffect(() => {
    const fetchFinanceDetailsSummary = async (id: string | number) => {
      try {
        showLoading();
        const response: AxiosResponse<MixAndMatchFinanceDetailsSummary> = await get(
          api(Consts.Api.MixAndMatchFinanceDetailsSummary.replace(':financeDetailsId', `${id}`))
        );
        setData(response.data);
      } catch (error: any) {
        alertService.clear(defaultAlertId);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    };

    if (financeDetailsId) {
      fetchFinanceDetailsSummary(financeDetailsId);
    }
  }, [financeDetailsId, hideLoading, showLoading, setData]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const downloadValues = async () => {
    try {
      showLoading();
      const response = await get(
        api(
          Consts.Api.MixAndMatchValuesDownload.replace(':financeDetailsId', `${financeDetailsId}`)
        ),
        {
          responseType: 'blob',
        }
      );
      const data = response.data;
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = 'DealValues.csv';
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const claimFrequencyLabel = useMemo(() => {
    if (!data?.claimInterval) {
      return null;
    }
    return data?.claimInterval === 'DaysAfterExpiry'
      ? Consts.DealClaimInterval.find(
          (interval) => interval.value === data.claimInterval
        )?.label.replace('Set', `${data?.claimDaysAfterExpiry ?? ''}`)
      : Consts.DealClaimInterval.find((interval) => interval.value === data.claimInterval)?.label;
  }, [data?.claimInterval, data?.claimDaysAfterExpiry]);

  const downloadSummaryReport = async () => {
    try {
      showLoading();
      const response = await get(
        api(Consts.Api.SummaryReportDownload.replace(':id', `${financeDetailsId}`)),
        {
          responseType: 'blob',
        }
      );
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      link.download = response.request
        .getResponseHeader('Content-Disposition')
        .split('filename="')[1]
        .split('";')[0];
      link.click();
      link.remove();
      hideLoading();
    } catch (error: any) {
      hideLoading();
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    }
  };

  const updateSupplierApprovalNumber = async (supplierApprovalNumber?: string) => {
    try {
      showLoading();
      await put(
        api(
          Consts.Api.MixAndMatchFinanceDetailsSupplierApprovalNumber.replace(
            ':financeDetailsId',
            `${data?.mixAndMatchFinanceDetailsId}`
          )
        ),
        {supplierApprovalNumber}
      );
      setData(
        (prevData) =>
          ({
            ...(prevData ?? {}),
            supplierApprovalNumber,
          } as MixAndMatchFinanceDetailsSummary)
      );
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  if (!data) {
    return null;
  }
  return (
    <Box sx={{backgroundColor: '#F7F9FA'}}>
      <TabsComponent
        tabs={Tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        sx={{
          background: '#F7F9FA',
          button: {
            '&.Mui-selected': {
              backgroundColor: '#F7F9FA !important',
            },
            backgroundColor: '#FFFFFF !important',
          },
        }}
      />
      <TableTabPanel
        value={selectedTab}
        index={TabsIndex.MixAndMatchInformation}
        style={{padding: '3.125rem'}}
      >
        <Stack direction="column" spacing={8}>
          <Stack
            direction="column"
            spacing={4}
            sx={{width: '100%', maxWidth: '48rem', minWidth: '24rem'}}
          >
            <Typography variant="h5">Finance Details</Typography>
            <SplitIconAmountComponent
              icon={<MoneyGraphIcon />}
              label="Total Amount Accrued"
              amount={data.accruedAmount}
              gstType={summaryGstType}
              style={{maxWidth: '36rem'}}
            />
            <Stack direction="column">
              <SplitTextComponent leftLabel="Claim Frequency" rightLabel={claimFrequencyLabel} />
              <SplitTextComponent
                leftLabel="Returns"
                rightLabel={data.excludeReturns ? 'Excluded' : 'Included'}
              />
              <SplitTextComponent
                leftLabel="Supplier Approval Number"
                rightLabelFullWidth
                rightLabel={
                  <EditableField
                    onSave={updateSupplierApprovalNumber}
                    value={data?.supplierApprovalNumber ?? ''}
                    direction="row"
                    readOnly={false}
                    validationSchema={supplierApprovalNumberValidationSchema}
                    name="supplierApprovalNumber"
                  />
                }
              />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={6}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <Typography variant="h5">Mix & Match Values</Typography>
              <ButtonsContainer>
                <SummaryReportDownloadButton onClick={downloadSummaryReport}>
                  Download Written Mix & Match Summary Report
                </SummaryReportDownloadButton>
                <DownloadButton onClick={downloadValues}>Download CSV Report</DownloadButton>
              </ButtonsContainer>
            </Stack>
            <MixAndMatchSummaryValuesTable
              valuesResponse={data?.mixAndMatchGroupProducts}
              financeDetailsId={data.mixAndMatchFinanceDetailsId}
            />
          </Stack>
        </Stack>
      </TableTabPanel>
      <TableTabPanel
        value={selectedTab}
        index={TabsIndex.Attachments}
        style={{padding: '3.125rem'}}
      >
        <Attachments financeDetailId={data.mixAndMatchFinanceDetailsId} show />
      </TableTabPanel>
    </Box>
  );
};

export default MixAndMatchFinanceDetails;
