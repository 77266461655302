import add from 'date-fns/add';
import sub from 'date-fns/sub';
import {getDomainConfig, TenantCountry} from '../utils/domainUtil';

const {country} = getDomainConfig();
const AgreementTypeEnum = {
  Deal: 'Deal',
  ContractAgreement: 'ContractAgreement',
};
const AgreementSubTypeEnum = {
  Standard: 'Standard',
  MixAndMatch: 'MixAndMatch',
  Spiv: 'Spiv',
};
const StandardDealTypeEnum = {
  Sales: 'Sales',
  Purchases: 'Purchases',
  FixedAmount: 'FixedAmount',
};
const DealTypeEnum = {
  Sales: 'Sales',
  Purchases: 'Purchases',
  FixedAmount: 'FixedAmount',
  MixAndMatch: 'MixAndMatch',
  Spiv: 'Spiv',
};
const RebateTypeEnum = {
  Sales: 'Sales',
  Purchases: 'Purchases',
};
const AmountTypeEnum = {
  ValuePerUnit: 'ValuePerUnit',
  Percentage: 'Percentage',
  FixedAmount: 'FixedAmount',
  SetPrice: 'SetPrice',
  DiscountAmount: 'DiscountAmount',
  BundlePrice: 'BundlePrice',
};
const SplitAccountAmountTypeEnum = {
  Value: 'Value',
  Percentage: 'Percentage',
};
const PriceTypeEnum = {
  ValuePerUnit: 'ValuePerUnit',
  Percentage: 'Percentage',
};
const DealCapTypeEnum = {
  UnitCount: 'UnitCount',
  TotalValue: 'TotalValue',
};
const GstTypeEnum = {
  Exclusive: 'Exclusive',
  Inclusive: 'Inclusive',
  Free: 'Free',
};
const DealClaimIntervalEnum = {
  StartOfRebate: 'StartOfRebate',
  EndOfRebate: 'EndOfRebate',
  Immediately: 'Immediately',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  DaysAfterExpiry: 'DaysAfterExpiry',
  DoNotClaim: 'DoNotClaim',
};
const RebateClaimIntervalEnum = {
  EndOfRebate: 'EndOfRebate',
  Immediately: 'Immediately',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  SemiAnnually: 'SemiAnnually',
  Annually: 'Annually',
  DoNotClaim: 'DoNotClaim',
};

const UserRoleEnum = {
  ViewDealsAndContractAgreements: 'ViewDealsAndContractAgreements',
  AddOrUpdateDeals: 'AddOrUpdateDeals',
  ApproveDealsAndContractAgreements: 'ApproveDealsAndContractAgreements',
  ManageDealClaims: 'ManageDealClaims',
  AddOrUpdateContractAgreements: 'AddOrUpdateContractAgreements',
  ManageContractAgreementClaims: 'ManageContractAgreementClaims',
  DeleteDeals: 'DeleteDeals',
  DeleteContractAgreements: 'DeleteContractAgreements',
  ManageUnreleasedAmounts: 'ManageUnreleasedAmounts',
  BulkUploadEditRebateClaims: 'BulkUploadEditRebateClaims',
  ManageMixAndMatches: 'ManageMixAndMatches',
  ManageSpivs: 'ManageSpivs',
  ViewCatalogues: 'ViewCatalogues',
  AddOrUpdateCatalogues: 'AddOrUpdateCatalogues',
  DeleteCatalogues: 'DeleteCatalogues',
};

const AgreementStatusEnum = {
  Draft: 'Draft',
  AwaitingApproval: 'AwaitingApproval',
  Approved: 'Approved',
  ChangesRequired: 'ChangesRequired',
  DoNotClaim: 'DoNotClaim',
  ReadyToStart: 'ReadyToStart',
  InProgress: 'InProgress',
  Finished: 'Finished',
  Cancelled: 'Cancelled',
};
const RebateStatusEnum = {
  Draft: 'Draft',
  Approved: 'Approved',
  DoNotClaim: 'DoNotClaim',
  ReadyToStart: 'ReadyToStart',
  InProgress: 'InProgress',
  Finished: 'Finished',
};
const ClaimStatusEnum = {
  ReadyToProcess: 'ReadyToProcess',
  ReadyToSend: 'ReadyToSend',
  SentToSupplier: 'SentToSupplier',
  Finalised: 'Finalised',
  Invoiced: 'Invoiced',
  Raised: 'Raised',
  NoClaimRaised: 'NoClaimRaised',
};

const CatalogueStatusEnum = {
  ReadyToStart: 'ReadyToStart',
  InProgress: 'InProgress',
  Finished: 'Finished',
};

// MIX AND MATCH DEAL CONSTS
const MixAndMatchAmountTypeEnum = {
  ValuePerUnit: 'ValuePerUnit',
  Percentage: 'Percentage',
  BundlePrice: 'BundlePrice',
  AmountedPrice: 'AmountedPrice',
};

const MixAndMatchTypeEnum = {
  BuyAGetPercentageOff: 'BuyAGetPercentageOff',
  BuyAGetBFree: 'BuyAGetBFree',
  BuyAGetPercentageOffB: 'BuyAGetPercentageOffB',
  BuyAGetDollarOffB: 'BuyAGetDollarOffB',
  BuyAGetBForDollar: 'BuyAGetBForDollar',
  BuyAPlusBGetDollarOffC: 'BuyAPlusBGetDollarOffC',
  BuyAPlusBGetPercentageOffC: 'BuyAPlusBGetPercentageOffC',
  BuyAPlusBGetCFree: 'BuyAPlusBGetCFree',
  BuyNOfAGetOneFree: 'BuyNOfAGetOneFree',
  BuyNOfAGetPercentageOff: 'BuyNOfAGetPercentageOff',
  BuyNOffAForDollar: 'BuyNOfAForDollar',
  BuyAPlusBOrMoreForPercentageOffEach: 'BuyAPlusBOrMoreForPercentageOffEach',
  BuyAPlusBOrMoreForDollar: 'BuyAPlusBOrMoreForDollar',
};

const MixAndMatchGroupClaimedEnum = {
  NoClaim: 'NoClaim',
  SKU: 'SKU',
  ClaimVendor: 'ClaimVendor',
};

const SPIVMinSellPriceTypeEnum = {
  GoSell: 'GoSell',
  MinSellPrice: 'MinSellPrice',
};

const ProductListTemplateTypeEnum = {
  FixedDollarDeals: 'FixedDollarDeals',
  MixAndMatchDeals: 'MixAndMatchDeals',
  PurchaseDeals: 'PurchaseDeals',
  SalesDeals: 'SalesDeals',
  SkusCSV: 'SkusCSV',
  SkusExcel: 'SkusExcel',
  SPIVDeals: 'SPIVDeals',
};

export type InputDetail = {
  index: number;
  showDiscount?: boolean;
  showQuantity?: boolean;
  showQuantityLimit?: boolean;
  showBundlePrice?: boolean;
};
export type MNMTypeDetails = {
  label: string;
  groupInputConfig: InputDetail[];
  hide?: boolean;
};

/**
 * input config is provided for each group and applied according to the
 * array index of the group as received in api response
 */
const MixAndMatchTypeMapping: {[x: string]: MNMTypeDetails} = {
  [MixAndMatchTypeEnum.BuyAGetPercentageOff]: {
    label: 'Buy A Get % Off',
    groupInputConfig: [
      {
        index: 0,
        showDiscount: true,
      },
    ],
  },
  [MixAndMatchTypeEnum.BuyAGetBFree]: {
    label: 'Buy A Get B Free',
    groupInputConfig: [{index: 0}, {index: 1, showDiscount: true, showQuantityLimit: true}],
  },
  [MixAndMatchTypeEnum.BuyAGetPercentageOffB]: {
    label: 'Buy A Get % Off B',
    groupInputConfig: [
      {index: 0},
      {
        index: 1,
        showDiscount: true,
        showQuantityLimit: true,
      },
    ],
  },
  [MixAndMatchTypeEnum.BuyAGetDollarOffB]: {
    label: 'Buy A Get $ Off B',
    groupInputConfig: [
      {index: 0},
      {
        index: 1,
        showDiscount: true,
        showQuantityLimit: true,
      },
    ],
    hide: true,
  },
  [MixAndMatchTypeEnum.BuyAGetBForDollar]: {
    label: 'Buy A Get B for $',
    groupInputConfig: [
      {index: 0},
      {
        index: 1,
        showDiscount: true,
        showQuantityLimit: true,
      },
    ],
  },
  [MixAndMatchTypeEnum.BuyAPlusBGetDollarOffC]: {
    label: 'Buy A + B Get $ Off C',
    groupInputConfig: [{index: 0}, {index: 1}, {index: 2, showDiscount: true}],
    hide: true,
  },
  [MixAndMatchTypeEnum.BuyAPlusBGetPercentageOffC]: {
    label: 'Buy A + B Get % Off C',
    groupInputConfig: [{index: 0}, {index: 1}, {index: 2, showDiscount: true}],
  },
  [MixAndMatchTypeEnum.BuyAPlusBGetCFree]: {
    label: 'Buy A + B Get C Free',
    // free (100% off) is applied in the backend
    groupInputConfig: [{index: 0}, {index: 1}, {index: 2, showDiscount: true}],
  },
  [MixAndMatchTypeEnum.BuyNOfAGetOneFree]: {
    label: 'Buy 2 (or more) Get 1 Free',
    groupInputConfig: [{index: 0, showQuantity: true, showDiscount: true}],
  },
  [MixAndMatchTypeEnum.BuyNOfAGetPercentageOff]: {
    label: 'Buy 2 (or more) Get % Off',
    groupInputConfig: [{index: 0, showQuantity: true, showDiscount: true}],
  },
  [MixAndMatchTypeEnum.BuyNOffAForDollar]: {
    label: 'Buy 2 (or more) for $',
    groupInputConfig: [
      {
        index: 0,
        showQuantity: true,
        showDiscount: true,
      },
    ],
  },
  // bundle types
  [MixAndMatchTypeEnum.BuyAPlusBOrMoreForPercentageOffEach]: {
    label: 'Buy A + B (+C) for % Off Each',
    // use "empty" groupInputConfig to give a count for loading skeleton
    groupInputConfig: [{index: 0}, {index: 1}],
  },
  [MixAndMatchTypeEnum.BuyAPlusBOrMoreForDollar]: {
    label: 'Buy A + B (+C) for $',
    groupInputConfig: [{index: 0}, {index: 1}],
  },
};

type Tag = {
  label: string;
  value: string | null;
};

const ClaimFilterStatus = (my?: boolean): Tag[] => {
  return [
    {
      label: my ? 'My Claims' : 'All Claims',
      value: null,
    },
    {
      label: 'Ready to Process',
      value: ClaimStatusEnum.ReadyToProcess,
    },
    {
      label: 'Sent to Supplier',
      value: ClaimStatusEnum.SentToSupplier,
    },
    {
      label: 'Finalised',
      value: ClaimStatusEnum.Finalised,
    },
    {
      label: 'Invoiced',
      value: ClaimStatusEnum.Invoiced,
    },
    {
      label: 'Raised',
      value: ClaimStatusEnum.Raised,
    },
  ];
};

const DealFilterStatus = (my?: boolean) => {
  return [
    {
      label: my ? 'My Deals' : 'All Deals',
      value: null,
    },
    {
      label: 'Awaiting Approval',
      value: AgreementStatusEnum.AwaitingApproval,
    },
    {
      label: 'Ready to Start',
      value: AgreementStatusEnum.ReadyToStart,
    },
    {
      label: 'In Progress',
      value: AgreementStatusEnum.InProgress,
    },
    {
      label: 'Finished',
      value: AgreementStatusEnum.Finished,
    },
    {
      label: 'Cancelled',
      value: AgreementStatusEnum.Cancelled,
    },
  ];
};

const ContractAgreementsFilterStatus = (my?: boolean) => {
  return [
    {
      label: my ? 'My Agreements' : 'All Agreements',
      value: null,
    },
    {
      label: 'Awaiting Approval',
      value: AgreementStatusEnum.AwaitingApproval,
    },
    {
      label: 'Ready to Start',
      value: AgreementStatusEnum.ReadyToStart,
    },
    {
      label: 'In Progress',
      value: AgreementStatusEnum.InProgress,
    },
    {
      label: 'Finished',
      value: AgreementStatusEnum.Finished,
    },
  ];
};

const RebatesFilterStatus = (my?: boolean) => {
  return [
    {
      label: my ? 'My Rebates' : 'All Rebates',
      value: null,
    },
    {
      label: 'Ready to Start',
      value: RebateStatusEnum.ReadyToStart,
    },
    {
      label: 'In Progress',
      value: RebateStatusEnum.InProgress,
    },
    {
      label: 'Finished',
      value: RebateStatusEnum.Finished,
    },
  ];
};

const CataloguesFilterStatus = (my?: boolean) => [
  {
    label: my ? 'My Catalogues' : 'All Catalogues',
    value: null,
  },
  {
    label: 'Ready to Start',
    value: CatalogueStatusEnum.ReadyToStart,
  },
  {
    label: 'In Progress',
    value: CatalogueStatusEnum.InProgress,
  },
  {
    label: 'Finished',
    value: CatalogueStatusEnum.Finished,
  },
];

const WritebackTypeEnum = {
  None: 'null',
  Scanbacks: 'Scanbacks',
  BuyInDealsAndDisplayAllowance: 'BuyInDealsAndDisplayAllowance',
};

const AdjustmentTypeEnum = {
  Sku: 'Sku',
  Agreement: 'Agreement',
};
const AdjustmentType = [
  {label: 'SKU', value: AdjustmentTypeEnum.Sku},
  {
    label: 'Agreement',
    value: AdjustmentTypeEnum.Agreement,
  },
];
const AdjustmentReasonEnum = {
  DocumentMismatch: 'DocumentMismatch',
  DifferentRebateRate: 'DifferentRebateRate',
  VendorMismatch: 'VendorMismatch',
  BalanceWriteoff: 'BalanceWriteoff',
};
const AdjustmentReason = [
  {
    label: 'Document mismatch',
    value: AdjustmentReasonEnum.DocumentMismatch,
  },
  {
    label: 'Different rebate rate',
    value: AdjustmentReasonEnum.DifferentRebateRate,
  },
  {
    label: 'Vendor mismatch',
    value: AdjustmentReasonEnum.VendorMismatch,
  },
  {
    label: 'Balance writeoff',
    value: AdjustmentReasonEnum.BalanceWriteoff,
  },
];

const EffectOnNetPurchases = {
  None: '-',
  Decrease: 'Decrease',
  Increase: 'Increase',
};

const ProductBulkUploadErrorType = {
  None: 'None',
  LineErrors: 'LineError',
  Duplicates: 'Duplicates',
  LineWarnings: 'LineWarning',
  SKUOverlap: 'SKUOverlap',
};
const ConfigNameEnum = {
  DealLockDate: 'DealLockDate',
  DealCutoffDate: 'DealCutoffDate',
};

const Consts = {
  ITSupportEmail: 'itsupport@jbhifi.com.au',
  ItSupport: 'IT support',
  GraphProfileEndpoint: 'https://graph.microsoft.com/v1.0/me',
  ConfigNameEnum: ConfigNameEnum,
  Api: {
    Adjustment: '/Adjustments/:id',
    Adjustments: '/Adjustments',
    AdjustmentsDownload: '/Adjustments/Download',
    AdjustmentsUpload: '/Adjustments/Upload',
    Attachment: '/Attachment/:id',
    Attachments: '/Attachments/:id',
    AttachmentDownload: '/Attachment/:id/Download',
    BuyerDepartments: '/BuyerDepartments',
    ClaimAdjustment: '/ClaimAdjustment',
    ClaimDetails: '/Claims/:id',
    Claims: '/Claims',
    ClaimsAdvance: '/Claims/Advance',
    ClaimsBulkUpload: '/Claims/BulkUpload',
    ClaimsDownload: '/Claims/Download',
    ClaimsInvoiceReview: '/Claims/InvoiceReview',
    ClaimsRollback: '/Claims/Rollback',
    Configs: '/Configs',
    ContractAgreement: '/ContractAgreements/:id',
    ContractAgreementClaims: '/ContractAgreements/:id/Claims',
    ContractAgreementDuplicate: '/ContractAgreements/Duplicate',
    ContractAgreementLookup: '/ContractAgreementLookup',
    ContractAgreementRebateValues: '/ContractAgreements/:id/RebateValues',
    ContractAgreementRebateValuesDownload: '/ContractAgreements/:id/Summary/RebateValues/Download',
    ContractAgreementRebates: '/ContractAgreements/:agreementId/Rebates',
    ContractAgreements: '/ContractAgreements',
    ContractAgreementsDownload: '/ContractAgreements/Download',
    ContractAgreementsRebateValues: '/ContractAgreements/:id/Summary/RebateValues',
    ContractAgreementsSummary: '/ContractAgreements/:id/Summary',
    DealAgreement: '/DealAgreements/:id',
    DealAgreementClaims: '/DealAgreements/:id/Claims',
    DealAgreementLinkedDeals: '/DealAgreements/:id/LinkedDeals',
    DealAgreementSummary: '/DealAgreements/:id/Summary',
    DealAgreementSummaryValues: '/DealAgreements/:id/Summary/DealValues',
    DealAgreementSuppliers: '/DealAgreements/:id/Suppliers',
    DealAgreementValues: '/DealAgreements/:id/DealValues',
    DealAgreements: '/DealAgreements',
    DealAgreementsDownload: '/DealAgreements/Download',
    DealAttachment: '/DealAgreements/:dealId/Attachments/:id',
    DealAttachments: '/DealAgreements/:dealId/Attachments',
    DealDuplicate: '/DealAgreements/Duplicate',
    DealFinanceDetails: '/DealAgreements/:id/FinanceDetails',
    DealReversal: '/DealAgreements/:id/CreateReversal',
    DealReversalFinish: '/DealAgreements/:id/FinishReversal',
    DealValue: '/DealValues/:id',
    DealValues: '/DealValues',
    DealValuesDownload: '/DealAgreements/:id/Summary/DealValues/Download',
    Departments: '/Departments',
    Drafts: '/Drafts',
    FinancialAccounts: '/FinancialAccounts',
    FinishContractAgreementCreation: '/ContractAgreements/:id/FinishCreation',
    FinishDealAgreementCreation: '/DealAgreements/:id/FinishCreation',
    FinishRebateCreation: '/Rebates/:id/FinishCreation',
    MixAndMatch: '/MixAndMatches/:id',
    MixAndMatchAmounts: '/MixAndMatches/:id/Amounts',
    MixAndMatchBulkUpload: '/MixAndMatches/Groups/:id/GroupProducts/BulkUpload',
    MixAndMatchCancel: '/MixAndMatches/:id/Cancel',
    MixAndMatchClaimOnGroup: '/MixAndMatches/Groups/:id/ClaimOnGroup',
    MixAndMatchClaims: '/MixAndMatches/:id/Claims',
    MixAndMatchCreate: '/MixAndMatches/Info',
    MixAndMatchDuplicate: '/MixAndMatches/:id/Duplicate',
    MixAndMatchFinanceDetails: '/MixAndMatches/:id/FinanceDetails',
    MixAndMatchFinanceDetailsProducts: '/MixAndMatches/FinanceDetails/:financeDetailsId/Products',
    MixAndMatchFinanceDetailsSummary: '/MixAndMatches/FinanceDetails/:financeDetailsId/Summary',
    MixAndMatchFinanceDetailsSupplierApprovalNumber:
      '/MixAndMatches/FinanceDetails/:financeDetailsId/SupplierApprovalNumber',
    MixAndMatchFinishCreation: '/MixAndMatches/:id/FinishCreation',
    MixAndMatchGroupProduct: '/MixAndMatches/Groups/GroupProducts/:id',
    MixAndMatchGroupProducts: '/MixAndMatches/Groups/:groupId/GroupProducts',
    MixAndMatchGroups: '/MixAndMatches/:id/Groups',
    MixAndMatchGroupsAdditionalBundleGroup: '/MixAndMatches/:id/Groups/AdditionalBundleGroup',
    MixAndMatchInfo: '/MixAndMatches/:id/Info',
    MixAndMatchSummary: '/MixAndMatches/:id/Summary',
    MixAndMatchType: '/MixAndMatches/:id/Type',
    MixAndMatchValuesDownload:
      '/MixAndMatches/FinanceDetails/:financeDetailsId/MixAndMatchValues/Download',
    MixAndMatches: '/MixAndMatches',
    MyProfile: '/MyProfile',
    Owners: '/Owners',
    PaymentPriorities: '/PaymentPriorities',
    ProductListGenerator: '/ProductSelector/GenerateProductList',
    Rebate: '/Rebates/:id',
    RebateAttachment: '/Rebates/:rebateId/Attachments/:id',
    RebateAttachments: '/Rebates/:rebateId/Attachments',
    RebateClaims: '/Rebates/:id/Claims',
    RebateComment: '/Rebates/:id/Comments',
    RebateDuplicate: '/Rebates/Duplicate',
    RebateFinanceDetails: '/Rebates/:id/FinanceDetails',
    RebateProductDownload: '/RebateValues/:id/Products/Download',
    RebateProducts: '/Rebates/:id/Products',
    RebateSummary: '/Rebates/:id/Summary',
    RebateValue: '/Rebates/:id/RebateValue',
    Rebates: '/Rebates',
    RebatesDownload: '/Rebates/Download',
    SummaryReportDownload: '/DealAgreements/:id/SummaryReport/Download',
    Spiv: '/Spivs/:id',
    SpivCancel: '/Spivs/:id/CancelSpiv',
    SpivDuplicate: '/Spivs/:id/Duplicate',
    SpivFinanceDetails: '/Spivs/:id/FinanceDetails',
    SpivFinishCreation: '/Spivs/:id/FinishCreation',
    SpivSummary: '/Spivs/:id/Summary',
    SpivSummaryValues: '/Spivs/:id/Summary/SpivValues',
    SpivSummaryReportDownload: '/Spivs/:id/SummaryReport/Download',
    SpivValue: 'Spivs/:id/SpivValues/:spivValueId',
    SpivValues: '/Spivs/:id/SpivValues',
    SpivValuesBulkUpload: '/Spivs/:id/SpivValues/BulkUpload',
    SpivValuesDownload: '/Spivs/:id/SpivValues/Download',
    Spivs: '/Spivs',
    Suppliers: '/Suppliers',
    UnreleasedAmountsAdvance: '/UnreleasedAmounts/Advance',
    UnreleasedAmountsProducts: '/UnreleasedAmounts/Products',
    UnreleasedAmountsRebates: '/UnreleasedAmounts/Rebates',
  },
  RouterPath: {
    Adjustments: '/adjustments',
    Catalogues: '/catalogues',
    Claims: '/claims',
    ClaimAdjustment: '/claims/:id/adjustment',
    ContractAgreementSummary: '/contract-agreement/:id/summary',
    ContractAgreements: '/contract-agreements',
    CreateAdjustment: '/adjustment/create',
    CreateCatalogue: '/catalogue/create',
    CreateContractAgreement: '/contract-agreement/create',
    CreateDeal: '/deal/create',
    CreateMixAndMatch: '/mix-and-match/create',
    CreateRebate: '/contract-agreement/:agreementId/rebate/create',
    CreateSPIV: '/spiv/create',
    DealSummary: '/deal/:id/summary',
    Deals: '/deals',
    Drafts: '/drafts',
    EditContractAgreement: '/contract-agreement/:id',
    EditDeal: '/deal/:id',
    DealReversal: '/deal/:id/reverse',
    EditMixAndMatch: '/mix-and-match/:id',
    EditRebate: '/rebate/:id',
    EditSpiv: '/spiv/:id',
    Home: '/',
    MixAndMatchSummary: '/mix-and-match/:id/summary',
    MyCatalogues: '/my-catalogues',
    MyClaims: '/my-claims',
    MyContractAgreements: '/my-contract-agreements',
    MyDeals: '/my-deals',
    MyDrafts: '/my-drafts',
    MyRebates: '/my-rebates',
    ProductListGenerator: '/product-list-generator',
    RebateSummary: '/rebate/:id/summary',
    Rebates: '/rebates',
    SPIVS: '/spivs',
    SPIVSummary: '/spiv/:id/summary',
    UnreleasedAmounts: '/unreleased-amounts',
  },
  PageSize: [10, 25, 50, 100],
  Narta: {
    Code: country === TenantCountry.NZ ? 'NARTANZ' : 'NARTA',
    Name: 'NARTA GROUP',
  },
  Date: {
    AdjustmentMinDate: '2020-07-01:00:00:00.000',
    RebateStartDateMin: '2020-07-01',
    AgreementStartDateMin: '2020-07-01',
    MaxEndDateMonthsFromNow: sub(add(new Date(), {months: 1000}), {days: 1}).toISOString(),
  },
  ValidationMessage: {
    Required: 'This field is required',
    Date: {
      FormatError: 'Please add a date in the following format DD/MM/YYYY',
      EndDateRangeError: 'Your end date cannot be set before the start date',
      MaxEndDateRangeError: 'The end date cannot be more than 1000 months from today',
      MinStartDateRangeError: 'The start date cannot be before 1/7/2020',
      AdjustmentDateRangeError: 'The value must be between 01/07/2020 and today',
      AgreementAdjustmentStartDateRangeError: `The value cannot be set after the selected agreement's end date`,
      AgreementAdjustmentEndDateRangeError: `The value cannot be set before the selected agreement's start date`,
      MinimumTenMinutesFromNow: 'Cannot be prior to today - Can be today at least 10 mins from now',
    },
    Time: {
      FormatError: 'Please add a time in the following format HH:mm',
      EndTimeRangeError: 'Your end time cannot be set before the start time',
    },
    NumberOnly: 'Invalid format (use numbers only)',
    PercentageTotal: 'The total percentage must be 100',
    PercentageValue: 'The value must be in scope (0, 100)',
    PercentageValueWith100: 'The value must be in scope (-100, 100)',
    PositiveValue: 'The value must be greater than 0',
    NegativeValue: 'The value must be less than 0',
    ProductSupplierMismatch: 'Product supplier does not match selected suppliers',
    DealNonPrintableCharsError:
      'Deal description contains an invalid character. Please remove the invalid character, then select Next to continue.',
    AgreementNonPrintableCharsError:
      'Agreement description contains an invalid character. Please remove the invalid character, then select Finish to continue.',
    RebateNonPrintableCharsError:
      'Rebate description contains an invalid character. Please remove the invalid character, then select Next to continue.',
  },
  AlgoliaIndex: {
    ClaimVendors: `claim_vendors_${process.env.REACT_APP_REBATES_ENV.toLowerCase()}`,
    Locations: `locations_${process.env.REACT_APP_REBATES_ENV.toLowerCase()}`,
    Products: `all_products_${process.env.REACT_APP_REBATES_ENV.toLowerCase()}`,
  },
  AlgoliaFacet: {
    State: {
      Index: 'physicalAddress.state',
      Type: 'State',
      Label: 'State',
      PluralLabel: 'State(s)',
      Placeholder: 'Select or search for a state',
    },
    LocationType: {
      Index: 'subType',
      Type: 'LocationType',
      Label: 'Location Type',
      PluralLabel: 'Location Type(s)',
      Placeholder: 'Select or search for a location type',
    },
    Location: {
      Index: 'idName',
      Type: 'Location',
      Label: 'Location',
      PluralLabel: 'Location(s)',
      Placeholder: 'Select or search for a location',
      Composited: true,
    },
    ProductBrand: {
      Index: 'product.brand',
      Type: 'Brand',
      Label: 'Brand',
      PluralLabel: 'Brand(s)',
      Placeholder: 'Select or search for a brand',
    },
    ProductDepartment: {
      Index: 'product.departmentIdAndDescription',
      // if the value is the combination of two attribute, the Composited is true,
      // like 'id--description' or 'number-name'.
      // In frontend algolia refinement, the whole value is used for facet filter,
      //  but only the second part(description, name) is used for display.
      // In backend, the id or code is used for search.
      Composited: true,
      Type: 'Department',
      Label: 'Department',
      PluralLabel: 'Department(s)',
      Placeholder: 'Select or search for a department',
    },
    ProductGroup: {
      Index: 'product.productGroupIdAndDescription',
      Composited: true,
      Type: 'ProductGroup',
      Label: 'Product Group',
      PluralLabel: 'Product Group(s)',
      Placeholder: 'Select or search for a product group',
    },
    ProductSupplier: {
      Index: 'product.suppliers.supplierNumberAndName',
      Composited: true,
      Type: 'Supplier',
      Label: 'Supplier',
      PluralLabel: 'Supplier(s)',
      Placeholder: 'Select or search for a supplier',
    },
    ProductSeasonCode: {
      Index: 'product.seasonCode',
      Type: 'Season',
      Label: 'Season Code',
      PluralLabel: 'Season Code(s)',
      Placeholder: 'Select or search for a season code',
    },
    ProductSku: {
      Index: 'skuTitle',
      Composited: true,
      Type: 'Product',
      Label: 'SKU',
      PluralLabel: 'SKU(s)',
      Placeholder: 'Select or search for a sku',
    },
    ProductSkuBulk: {
      Index: 'skuTitle',
      Composited: true,
      Type: 'Product',
      Label: 'SKU (Bulk List)',
      PluralLabel: 'SKU(s)',
      Placeholder: 'Enter comma separated SKU numbers',
    },
    ProductSpecification: {
      Index: 'product.specifications.specificationNameAndValue',
      Type: 'Specification',
      Label: 'Specification',
      PluralLabel: 'Specification(s)',
      Placeholder: 'Select or search for a specification',
    },
  },
  AgreementTypeEnum: AgreementTypeEnum,
  AgreementSubTypeEnum,
  StandardDealTypeEnum: StandardDealTypeEnum,
  DealTypeEnum,
  StandardDealTypes: [
    {label: 'Sales', value: StandardDealTypeEnum.Sales},
    {label: 'Purchases', value: StandardDealTypeEnum.Purchases},
    {label: 'A Fixed Amount', value: StandardDealTypeEnum.FixedAmount},
  ],
  DealTypes: [
    {label: 'Sales', value: DealTypeEnum.Sales},
    {label: 'Purchases', value: DealTypeEnum.Purchases},
    {label: 'A Fixed Amount', value: DealTypeEnum.FixedAmount},
    {label: 'Mix and Match', value: DealTypeEnum.MixAndMatch},
    {label: 'Spiv', value: DealTypeEnum.Spiv},
  ],
  RebateTypeEnum: RebateTypeEnum,
  RebateTypes: [
    {label: 'Sales', value: RebateTypeEnum.Sales},
    {label: 'Purchases', value: RebateTypeEnum.Purchases},
  ],
  AmountTypeEnum: AmountTypeEnum,
  AmountType: [
    {
      label: '$ p.unit',
      value: AmountTypeEnum.ValuePerUnit,
    },
    {
      label: '%',
      value: AmountTypeEnum.Percentage,
    },
    {
      label: '$',
      value: AmountTypeEnum.FixedAmount,
    },
  ],
  ClaimAmountType: [
    {
      label: '$ p.unit',
      value: AmountTypeEnum.ValuePerUnit,
    },
    {
      label: '%',
      value: AmountTypeEnum.Percentage,
    },
  ],
  SplitAccountAmountTypeEnum: SplitAccountAmountTypeEnum,
  SplitAccountAmountType: [
    {
      label: '$',
      value: SplitAccountAmountTypeEnum.Value,
    },
    {
      label: '%',
      value: SplitAccountAmountTypeEnum.Percentage,
    },
  ],
  GstTypeEnum: GstTypeEnum,
  GstType: [
    {
      label: 'Exclusive',
      value: GstTypeEnum.Exclusive,
    },
    {
      label: 'Inclusive',
      value: GstTypeEnum.Inclusive,
    },
    {
      label: 'Free',
      value: GstTypeEnum.Free,
    },
  ],
  PriceTypeEnum: PriceTypeEnum,
  DealPromoPriceType: [
    {
      label: '$ per unit',
      value: PriceTypeEnum.ValuePerUnit,
    },
    {
      label: '%',
      value: PriceTypeEnum.Percentage,
    },
  ],
  DealCapTypeEnum: DealCapTypeEnum,
  DealCapType: [
    {
      label: 'Unit',
      value: DealCapTypeEnum.UnitCount,
    },
    {
      label: '$',
      value: DealCapTypeEnum.TotalValue,
    },
  ],
  DealClaimIntervalEnum: DealClaimIntervalEnum,
  DealClaimInterval: [
    {
      label: 'At the start of the deal',
      value: DealClaimIntervalEnum.StartOfRebate,
    },
    {
      label: 'At the end of the deal',
      value: DealClaimIntervalEnum.EndOfRebate,
    },
    {
      label: 'Immediately',
      value: DealClaimIntervalEnum.Immediately,
    },
    {
      label: 'Every month',
      value: DealClaimIntervalEnum.Monthly,
    },
    {
      label: 'Every quarter',
      value: DealClaimIntervalEnum.Quarterly,
    },
    {
      label: 'Set days past expiry date',
      value: DealClaimIntervalEnum.DaysAfterExpiry,
    },
    {
      label: 'Do not claim',
      value: DealClaimIntervalEnum.DoNotClaim,
    },
  ],
  RebateClaimIntervalEnum: RebateClaimIntervalEnum,
  RebateClaimInterval: [
    {
      label: 'At the end of rebate',
      value: RebateClaimIntervalEnum.EndOfRebate,
    },
    {
      label: 'Every month',
      value: RebateClaimIntervalEnum.Monthly,
    },
    {
      label: 'Every quarter',
      value: RebateClaimIntervalEnum.Quarterly,
    },
    {
      label: 'Every six months',
      value: RebateClaimIntervalEnum.SemiAnnually,
    },
    {
      label: 'Every year',
      value: RebateClaimIntervalEnum.Annually,
    },
    {
      label: 'Do not claim',
      value: RebateClaimIntervalEnum.DoNotClaim,
    },
  ],
  SPIVClaimInterval: [
    {
      label: 'At the end of SPIV',
      value: RebateClaimIntervalEnum.EndOfRebate,
    },
    {
      label: 'Set days past expiry date',
      value: DealClaimIntervalEnum.DaysAfterExpiry,
    },
    {
      label: 'Do not claim',
      value: RebateClaimIntervalEnum.DoNotClaim,
    },
  ],
  AgreementStatusEnum: AgreementStatusEnum,
  AgreementsStatus: {
    [AgreementStatusEnum.Draft]: 'Draft',
    [AgreementStatusEnum.AwaitingApproval]: 'Awaiting Approval',
    [AgreementStatusEnum.ReadyToStart]: 'Ready to Start',
    [AgreementStatusEnum.InProgress]: 'In Progress',
    [AgreementStatusEnum.Finished]: 'Finished',
    [AgreementStatusEnum.DoNotClaim]: 'Do not claim',
    [AgreementStatusEnum.ChangesRequired]: 'Changes Required',
    [AgreementStatusEnum.Cancelled]: 'Cancelled',
  },
  RebateStatusEnum: RebateStatusEnum,
  RebateStatus: {
    [RebateStatusEnum.Draft]: 'Draft',
    [RebateStatusEnum.ReadyToStart]: 'Ready to Start',
    [RebateStatusEnum.InProgress]: 'In Progress',
    [RebateStatusEnum.Finished]: 'Finished',
    [RebateStatusEnum.DoNotClaim]: 'Do not claim',
  },
  DealsFilterStatus: DealFilterStatus,
  ContractAgreementsFilterStatus: ContractAgreementsFilterStatus,
  RebatesFilterStatus: RebatesFilterStatus,
  CataloguesFilterStatus,
  ClaimStatusEnum: ClaimStatusEnum,
  ClaimStatus: {
    [ClaimStatusEnum.ReadyToProcess]: 'Ready to Process',
    [ClaimStatusEnum.ReadyToSend]: 'Ready to Send',
    [ClaimStatusEnum.SentToSupplier]: 'Sent to Supplier',
    [ClaimStatusEnum.Finalised]: 'Finalised',
    [ClaimStatusEnum.Invoiced]: 'Invoiced',
    [ClaimStatusEnum.Raised]: 'Raised',
    [ClaimStatusEnum.NoClaimRaised]: 'No Claim Raised',
  },
  ClaimFilterStatus: ClaimFilterStatus,
  UserRoleEnum: UserRoleEnum,
  DefaultPagination: {
    pageSize: 100,
    currentPage: 1,
    totalCount: 0,
    totalPages: 1,
  },
  WritebackTypeEnum: WritebackTypeEnum,
  WritebackType: [
    {
      label: 'None',
      value: WritebackTypeEnum.None,
    },
    {
      label: 'Scanbacks',
      value: WritebackTypeEnum.Scanbacks,
    },
    {
      label: 'Buy-in Deals & Display Allowance',
      value: WritebackTypeEnum.BuyInDealsAndDisplayAllowance,
    },
  ],
  AdjustmentTypeEnum: AdjustmentTypeEnum,
  AdjustmentType: AdjustmentType,
  AdjustmentReasonEnum: AdjustmentReasonEnum,
  AdjustmentReason: AdjustmentReason,
  EffectOnNetPurchases: EffectOnNetPurchases,
  ProductBulkUploadErrorType: ProductBulkUploadErrorType,
  ProductListTemplateTypeEnum,
  ProductListGeneratorTemplateType: [
    {
      label: 'Fixed Dollar Product List (Deals)',
      value: ProductListTemplateTypeEnum.FixedDollarDeals,
    },
    {
      label: 'Mix & Match Product List (Deals)',
      value: ProductListTemplateTypeEnum.MixAndMatchDeals,
    },
    {label: 'Purchases Product List (Deals)', value: ProductListTemplateTypeEnum.PurchaseDeals},
    {label: 'Sales Product List (Deals)', value: ProductListTemplateTypeEnum.SalesDeals},
    {label: 'SKU List CSV', value: ProductListTemplateTypeEnum.SkusCSV},
    {label: 'SKU List Excel', value: ProductListTemplateTypeEnum.SkusExcel},
    {label: 'SPIV Product List (Deals)', value: ProductListTemplateTypeEnum.SPIVDeals},
  ],
  UnreleasedAmountsInactiveMessage: {
    Rebate: 'Inactive until tomorrow as one or more Products have been released',
    Product: 'Inactive until tomorrow as one or more Rebates have been released',
  },
  FeatureFlags: {
    EnableMixAndMatch: 'EnableMixAndMatch',
    EnableCatalogues: 'EnableCatalogues',
  },
  MixAndMatchTypeEnum,
  MixAndMatchTypeMapping,
  MixAndMatchAmountTypeEnum,
  MixAndMatchGroupClaimedEnum,
  SPIVMinSellPriceTypeEnum,
  SPIVMinSellPriceType: [
    {
      label: 'Go sell',
      value: SPIVMinSellPriceTypeEnum.GoSell,
    },
    {
      label: '$',
      value: SPIVMinSellPriceTypeEnum.MinSellPrice,
    },
  ],
  SummaryPageActionTypes: {
    DUPLICATE: 'duplicate',
    EDIT: 'edit',
    DELETE: 'delete',
    REVERSE: 'reverse',
  },
};

export default Consts;
